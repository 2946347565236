import React from 'react';
import NewPostView from '../NewPostView';
import Load from '../Load';
import i18n from '../../utils/i18n';

type Props = {
  feed: NewFeed;
  onLikePost?: (postIndex: number) => void;
  onDeletePost?: (postIndex: number) => void;
  isLoading?: boolean;
  isNextPageLoading?: boolean;
};

const Feed: React.FC<Props> = ({
  feed,
  onLikePost,
  onDeletePost,
  isLoading,
  isNextPageLoading,
}) => {
  if (isLoading) {
    return <Load />;
  }

  if (feed.posts.length) {
    return (
      <>
        {feed.posts.map((feedPost, index) => {
          return (
            <div key={feedPost.id}>
              <NewPostView
                feedPost={feedPost}
                onLikePost={onLikePost}
                onDeletePost={onDeletePost}
                feedPostIndex={index}
              />
            </div>
          );
        })}
        {isNextPageLoading && <Load />}
      </>
    );
  }

  return (
    <div
      className="align-self-center d-flex flex-column justify-content-center align-items-center"
      style={{
        marginTop: 50,
      }}
    >
      <img
        src="assets/images/logo-blank.png"
        alt="avater"
        style={{
          width: 50,
          height: 50,
        }}
      />
      <span className="text-grey-600 m-2 font-xss">
        {i18n.t<string>('Feed.noPosts')}
      </span>
    </div>
  );
};

export default Feed;
