import axios, { AxiosResponse } from 'axios';
import { session } from '../signals/session';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const registerUser = (
  username: string,
  email: string,
  password: string
) => {
  return new Promise<AxiosResponse>(async (resolve, reject) => {
    try {
      const resp = await axios.post(
        `${REACT_APP_API_URL}/user`,
        { username, email, password },
        { headers: { 'Content-Type': 'application/json' } }
      );
      resolve(resp);
    } catch (error) {
      reject(error);
    }
  });
};

export const getFollowers = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.get(`${REACT_APP_API_URL}/followers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(resp.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getSubscriptions = () => {
  return new Promise<Subscription[]>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.get(`${REACT_APP_API_URL}/subscription`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(resp.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getProfile = () => {
  return new Promise<MyProfile>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.get<MyProfile>(
        `${REACT_APP_API_URL}/profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // const profile: MyProfile = {
      //   about: resp.data ? resp.data.about : '',
      //   avatar: resp.data.avatar ? resp.data.avatar : '',
      //   cover: resp.data.cover ? resp.data.cover : '',
      //   fullname: resp.data.fullname ? resp.data.fullname : '',
      //   userId: resp.data.userId ? resp.data.userId : '',
      //   creatorId: resp.data.creatorId,
      //   username: '',
      // };

      resolve(resp.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateAvatar = (avatarId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.put(
        `${REACT_APP_API_URL}/profile`,
        { avatar: avatarId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(resp.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateCover = (coverId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.put(
        `${REACT_APP_API_URL}/profile`,
        { cover: coverId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(resp.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateFullname = (fullname: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.put(
        `${REACT_APP_API_URL}/profile`,
        { fullname },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(resp.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateAbout = (about: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.put(
        `${REACT_APP_API_URL}/profile`,
        { about },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(resp.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateProfile = (profile: Profile) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.put(
        `${REACT_APP_API_URL}/profile`,
        { ...profile },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(resp.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const follow = (creatorId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.post(
        `${REACT_APP_API_URL}/follower`,
        { creatorId },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(resp);
    } catch (error) {
      reject(error);
    }
  });
};

export const unfollow = (creatorId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.delete(
        `${REACT_APP_API_URL}/follower/${creatorId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      resolve(resp);
    } catch (error) {
      reject(error);
    }
  });
};

export const getSuggestions = () => {
  return new Promise<Suggestion[]>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.get<Suggestion[]>(
        `${REACT_APP_API_URL}/suggest`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(resp.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const creator = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.post(
        `${REACT_APP_API_URL}/creator`,
        { about: 'Description' },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(resp.data);
    } catch (error) {
      reject(error);
    }
  })
}
