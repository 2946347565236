import axios, { AxiosResponse } from 'axios';
import { session } from '../signals/session';
import { creator } from '../signals/creator';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const savePlan = (value: number) => {
  return new Promise<AxiosResponse>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.post(
        `${REACT_APP_API_URL}/plan`,
        { value },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      resolve(resp);
    } catch (error) {
      reject(error);
    }
  });
};

export const getPlan = () => {
  return new Promise<AxiosResponse>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.get(`${REACT_APP_API_URL}/plan`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      resolve(resp);
    } catch (error) {
      reject(error);
    }
  });
};

export const getPlansByCreatorId = (creatorId: string) => {
  return new Promise<Plan[]>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.get(
        `${REACT_APP_API_URL}/creator/${creatorId}/plan`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      resolve(resp.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const subscribe = (creatorId: string, planId: string) => {
  return new Promise<string>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.post(
        `${REACT_APP_API_URL}/subscription`,
        { creatorId, planId },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      resolve(resp.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const cancelSubscription = (subscriptionId: string) => {
  return new Promise<string>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      console.log(token);

      const resp = await axios.patch(
        `${REACT_APP_API_URL}/subscription/${subscriptionId}/cancel`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      resolve(resp.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const ledger = () => {
  return new Promise<Ledger>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.get(`${REACT_APP_API_URL}/ledger`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      resolve(resp.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const becomeCreator = (data: CreatorRegistration) => {
  return new Promise<string>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.post(
        `${REACT_APP_API_URL}/creator`,
        { ...data },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      resolve(resp.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCreator = () => {
  return new Promise<Creator | null>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.get(`${REACT_APP_API_URL}/creator`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      resolve(resp.data);
    } catch (error: any) {
      if (error.response?.status === 404) {
        resolve(null);
        return;
      }
      reject(error);
    }
  });
};

export const getSubscribers = () => {
  return new Promise<Subscribers[]>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.get(
        `${REACT_APP_API_URL}/creator/${creator.value?.id}/subscription`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(resp.data);
    } catch (error: any) {
      reject(error);
    }
  });
};

export const withdraw = () => {
  return new Promise<Subscribers[]>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.patch(
        `${REACT_APP_API_URL}/withdraw`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(resp.data);
    } catch (error: any) {
      reject(error);
    }
  });
};

export const saveAccountBank = (bankAccount: BankAccount) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.post(
        `${REACT_APP_API_URL}/account`,
        { ...bankAccount },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(resp.data);
    } catch (error: any) {
      reject(error);
    }
  });
};

export const getBankAccount = () => {
  return new Promise<BankAccount>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.get(`${REACT_APP_API_URL}/account`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(resp.data);
    } catch (error: any) {
      reject(error);
    }
  });
};

export const updateBankAccount = (bankAccount: BankAccount) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const { documentType, document, name, pixKey, bank } = bankAccount;

      const resp = await axios.put(
        `${REACT_APP_API_URL}/account/${bankAccount.id}`,
        { documentType, document, name, pixKey, bank },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(resp.data);
    } catch (error: any) {
      reject(error);
    }
  });
};
