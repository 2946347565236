import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { myProfile } from '../signals/profile';
import SearchBar from './SearchBar';
import { effect } from '@preact/signals';
import { scrolling } from '../signals/feedScrollPage';
import { isMobile } from '../utils/data';
import { isCreator } from '../signals/creator';

const Header: React.FC = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [isScrollingPage, setIsScrollingPage] = useState(false);

  effect(() => {
    if (!isMobile()) return;

    if (!isScrollingPage && scrolling.value) {
      setIsScrollingPage(true);
    } else if (isScrollingPage && !scrolling.value) {
      setIsScrollingPage(false);
    }
  });

  return (
    <div
      className="nav-header shadow-xs border-0"
      style={{ visibility: isScrollingPage ? 'hidden' : 'visible' }}
    >
      <div className="nav-top d-flex justify-content-between">
        <Link to={isCreator.value ? '/myprofile' : '/'}>
          <img
            src="assets/images/logo.png"
            style={{
              maxHeight: 145,
              maxWidth: 145,
            }}
            alt=""
          />
        </Link>
      </div>
      <span
        className={`p-2 pointer text-center ms-auto menu-icon`}
        id="dropdownMenu3"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      />
      <Link to="/menu" className="p-0 ms-3 menu-icon">
        <figure className="avatar">
          {myProfile.value.profile.avatar ? (
            <img
              src={myProfile.value.profile.avatar}
              alt="avater"
              className="float-right p-1 bg-white rounded-circle w60"
            />
          ) : (
            <i className="feather-user font-xl text-warning" />
          )}
        </figure>
      </Link>
      <div className={`app-header-search ${showSearch ? 'show' : ''}`}>
        <form className="search-form">
          <div className="form-group searchbox mb-0 border-0 p-1">
            <div className="me-5">
              <SearchBar />
            </div>
            <i
              className="ti-close font-xs"
              onClick={() => setShowSearch(false)}
            ></i>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Header;
