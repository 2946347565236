import React from 'react';

type Props = {
  profile: NewProfile;
};

const ProfileBanner: React.FC<Props> = ({ profile }) => {
  return (
    <div className="card border-0 w-100">
      <img
        src={`${profile.cover ?? 'assets/images/banner-1200x250.png'}`}
        alt="avatar"
        style={{
          margin: 10,
          borderRadius: 10,
          maxHeight: 250,
        }}
      />
    </div>
  );
};

export default ProfileBanner;
