import React, { useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Select from 'react-select';

import Header from '../components/Header';
import Appfooter from '../components/Appfooter';

import LeftMenu from '../components/LeftMenu';
import { gendersData, pronounsData, statesData } from '../utils/data';
import i18n from '../utils/i18n';
import { Spinner } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import { validateCPF, validateDate, isMinimumAge } from '../utils/validation';

type FormValues = {
  firstName: string;
  lastName: string;
  birthdate: string;
  gender: string;
  pronoun: string;
  cpf: string;
  address: string;
  complement: string;
  city: string;
  state: string;
};

const Account = () => {
  const scrollableDivRef = useRef(null);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    birthdate: Yup.string()
      .test('is-valid-date', i18n.t('BecomeCreator.form.errors.invalidDate'), (value) => 
        !value || validateDate(value)
      )
      .test('is-adult', i18n.t('BecomeCreator.form.errors.mustBeAdult'), (value) => 
        !value || isMinimumAge(value, 18)
      ),
    gender: Yup.string(),
    pronoun: Yup.string(),
    cpf: Yup.string()
      .test('is-valid-cpf', i18n.t('BecomeCreator.form.errors.invalidCpf'), (value) => 
        !value || validateCPF(value)
      ),
    address: Yup.string(),
    complement: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    isValid,
    values,
    errors,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      birthdate: '',
      gender: '',
      pronoun: '',
      cpf: '',
      address: '',
      complement: '',
      city: '',
      state: '',
    },
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    validateOnChange: true,
  });

  function onSubmit(values: FormValues) {
    try {
      // if (!isValidDate(new Date(values.birthdate))) {
      //   setFieldError('birthdate', 'Invalid Date');
      //   return;
      // }

      // const parsedDate = parse(values.birthdate, 'dd/MM/yyyy', new Date());
      // console.log(parsedDate)

      // const formattedDate = format(parsedDate.toISOString(), 'yyyy-MM-dd');

      setLoading(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const genders = useMemo(() => {
    const genders: { label: string; value: string }[] = [];

    for (const gender of gendersData) {
      genders.push({
        label: i18n.t(`Genders.${gender.value}`),
        value: gender.value,
      });
    }

    return genders;
  }, []);

  const pronouns = useMemo(() => {
    const pronouns: { label: string; value: string }[] = [];

    for (const pronoun of pronounsData) {
      pronouns.push({
        label: i18n.t(`Pronouns.${pronoun.value}`),
        value: pronoun.value,
      });
    }

    return pronouns;
  }, []);

  const states = useMemo(() => {
    const states: { label: string; value: string }[] = [];

    for (const state of statesData) {
      states.push({
        label: state.value,
        value: state.value,
      });
    }

    return states;
  }, []);

  return (
    <>
      <Header />
      <LeftMenu />
      <div className="main-content right-chat-active" ref={scrollableDivRef}>
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 border-0 d-flex rounded-3">
                  <button
                    onClick={() => navigate(-1)}
                    className="d-inline-block mt-2 bg-transparent border-0"
                  >
                    <i className="ti-arrow-left font-sm text-black"></i>
                  </button>
                  <h4 className="font-xs text-black fw-600 ms-4 mb-0 mt-2">
                    Minha Conta
                  </h4>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            Nome
                          </label>
                          <input
                            type="text"
                            className="form-control font-xss"
                            disabled={loading}
                            value={values.firstName}
                            placeholder="Nome"
                            name="firstName"
                            id="firstName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {errors.firstName ? errors.firstName : ''}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            Sobrenome
                          </label>
                          <input
                            type="text"
                            className="form-control font-xss"
                            disabled={loading}
                            value={values.lastName}
                            placeholder="Sobrenome"
                            name="lastName"
                            id="lastName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {errors.lastName ? errors.lastName : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            Data de Nascimento
                          </label>
                          <InputMask
                            className="form-control"
                            mask="99/99/9999"
                            value={values.birthdate}
                            placeholder="DD/MM/YYYY"
                            name="birthdate"
                            id="birthdate"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {errors.birthdate ? errors.birthdate : ''}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            Gênero
                          </label>
                          <Select
                            onChange={(gender) => {
                              setFieldValue('gender', gender?.value);
                            }}
                            value={{
                              label: values.gender
                                ? i18n.t(`Genders.${values.gender}`)
                                : '',
                              value: values.gender,
                            }}
                            options={genders}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {errors.gender ? errors.gender : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            Pronome
                          </label>
                          <Select
                            onChange={(pronoun) => {
                              setFieldValue('pronoun', pronoun?.value);
                            }}
                            value={{
                              label: values.pronoun
                                ? i18n.t(`Pronouns.${values.pronoun}`)
                                : '',
                              value: values.pronoun,
                            }}
                            options={pronouns}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {errors.pronoun ? errors.pronoun : ''}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            CPF
                          </label>
                          <InputMask
                            className="form-control"
                            mask="999.999.999-99"
                            placeholder="000.000.000-00"
                            value={values.cpf}
                            name="cpf"
                            id="cpf"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {errors.cpf ? errors.cpf : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                    <h4 className="font-xs text-black fw-600 mb-4 mt-4">
                      Seu Endereço
                    </h4>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            Endereço
                          </label>
                          <input
                            type="text"
                            className="form-control font-xss"
                            disabled={loading}
                            value={values.address}
                            placeholder="Endereço"
                            name="address"
                            id="address"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {errors.address ? errors.address : ''}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            Complemento
                          </label>
                          <input
                            type="text"
                            className="form-control font-xss"
                            disabled={loading}
                            value={values.complement}
                            placeholder="Complemento"
                            name="complement"
                            id="complement"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {errors.complement ? errors.complement : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            Cidade
                          </label>
                          <input
                            type="text"
                            className="form-control font-xss"
                            disabled={loading}
                            value={values.city}
                            placeholder="Cidade"
                            name="city"
                            id="city"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {errors.city ? errors.city : ''}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                            Estado
                          </label>
                          <Select
                            onChange={(state) => {
                              setFieldValue('state', state?.value);
                            }}
                            value={{
                              label: values.state,
                              value: values.state,
                            }}
                            options={states}
                          />
                          <span className="text-danger font-xsss fw-500">
                            {errors.state ? errors.state : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-12">
                        {loading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            className="text-warning mt-3"
                          />
                        ) : (
                          <button
                            onClick={() => handleSubmit()}
                            disabled={!isValid}
                            className="bg-warning border-0 text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                            style={!isValid ? { opacity: 0.5 } : { opacity: 1 }}
                          >
                            Salvar
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Appfooter />
    </>
  );
};

export default Account;
