import React from 'react';
import CreatorMenu from './CreatorMenu';
import DefaultMenu from './DefaultMenu';
import { isCreator } from '../../signals/creator';

const Menu = () => {
  return isCreator.value ? <CreatorMenu /> : <DefaultMenu />
};

export default Menu;
