import React from 'react';

import LeftCreatorMenu from './LeftCreatorMenu';
import LeftDefaultMenu from './LeftDefaultMenu';
import { isCreator } from '../../signals/creator';

const LeftMenu = () => {
  return isCreator.value ? <LeftCreatorMenu /> : <LeftDefaultMenu /> 
}

export default LeftMenu;
