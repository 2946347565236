import React, { ReactElement, useCallback, useEffect, useRef } from 'react';
import { scrolling } from '../../signals/feedScrollPage';
import { Spinner } from 'react-bootstrap';

type Props = {
  loading: boolean;
  footerLoading: boolean;
  children?: ReactElement;
  feed?: NewFeed;
  onReachOutBottom?: () => void;
};

let scrollingTimer: any;

const FeedScrollPage: React.FC<Props> = ({
  children,
  loading,
  footerLoading,
  feed,
  onReachOutBottom,
}) => {
  const containerRef = useRef(null);

  useEffect(() => {
    return () => {
      scrolling.value = false;
    };
  }, []);

  const handleScroll = useCallback(() => {
    const listContainer: any = containerRef.current;

    if (!listContainer) {
      return;
    }

    scrolling.value = listContainer.scrollTop > 200;

    // Clear our timeout throughout the scroll
    window.clearTimeout(scrollingTimer);

    // Set a timeout to run after scrolling ends
    scrollingTimer = setTimeout(function () {
      // Run the callback
      scrolling.value = false;
    }, 500);

    if (
      listContainer.scrollTop + listContainer.clientHeight >=
      listContainer.scrollHeight
    ) {
      if (
        !loading &&
        !footerLoading &&
        feed &&
        feed.posts.length &&
        feed.pagination?.page <= feed.pagination?.totalPages
      ) {
        onReachOutBottom && onReachOutBottom();
      }
    }
  }, [feed, footerLoading, loading, onReachOutBottom]);

  useEffect(() => {
    const listContainer: any = containerRef.current;
    if (listContainer) {
      listContainer.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (listContainer) {
        listContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <div
      className="main-content"
      ref={containerRef}
      style={{ overflow: 'scroll' }}
    >
      {loading ? (
        <div
          className="align-self-center d-flex flex-column justify-content-center align-items-center"
          style={{
            marginTop: 200,
          }}
        >
          <Spinner animation="border" role="status" className="text-warning" />
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default FeedScrollPage;
