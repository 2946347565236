import React, { useEffect } from 'react';

import Header from '../../components/Header';
import Appfooter from '../../components/Appfooter';
import NewFeedScrollPage from '../../components/Utility/NewFeedScrollPage';
import NewFeed from '../../components/Utility/NewFeed';
import LeftMenu from '../../components/LeftMenu';
import Suggestions from '../../components/Suggestions';
import newUseFeed from '../../hooks/newUseFeed';
import BecomeCreatorBanner from '../../components/BecomeCreatorBanner';
import FeedTab from '../../components/FeedTab';
import { isMobile } from '../../utils/data';
import { isCreator } from '../../signals/creator';

const PopularNewsfeed = () => {
  const {
    feed,
    suggestions,
    isFeedLoading,
    isFeedNextPageLoading,
    loadPreviewPage,
    loadSuggestions,
    onLikePost,
  } = newUseFeed();

  useEffect(() => {
    loadPreviewPage();
    loadSuggestions();
  }, [loadPreviewPage, loadSuggestions]);

  return (
    <div>
      <Header />
      {isMobile() && <FeedTab selectedTabIndex={0} />}
      <LeftMenu />
      <NewFeedScrollPage
        loading={isFeedLoading}
        footerLoading={isFeedNextPageLoading}
        // onReachOutBottom={fetchFeedNextPage}
        feed={feed}
      >
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row" style={{ marginTop: isMobile() ? 50 : 0 }}>
              <div className="col-xl-8 mb-5">
                <NewFeed
                  isLoading={isFeedLoading}
                  isNextPageLoading={isFeedNextPageLoading}
                  feed={feed}
                  onLikePost={onLikePost}
                />
              </div>
              <div className="col-xl-4 suggestions">
                <Suggestions suggestionList={suggestions} />
                {!isCreator.value && <BecomeCreatorBanner />}
              </div>
            </div>
          </div>
        </div>
      </NewFeedScrollPage>
      <Appfooter />
    </div>
  );
};

export default PopularNewsfeed;
