import React, { useEffect, useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { subscribe } from '../../services/creator';
import i18n from '../../utils/i18n';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  plan: Plan;
};

const SubscriptionModal: React.FC<Props> = ({ isVisible, plan, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [url, setUrl] = useState('');

  const fetchUrl = useCallback(async () => {
    try {
      setLoading(true);
      const url = await subscribe(plan.creatorId, plan.id);
      setUrl(url);
    } catch {
      setLoading(false);
      setError(true);
    }
  }, [plan.creatorId, plan.id]);

  useEffect(() => {
    if (isVisible) {
      fetchUrl();
    }
    return () => {
      setLoading(false);
      setError(false);
    }
  }, [fetchUrl, isVisible, onClose, plan]);

  return (
    <Modal show={isVisible} size="lg">
      <Modal.Header
        closeButton
        onClick={onClose}
        className="bg-white"
        style={{
          border: 0,
          borderRadius: 0,
          padding: 20,
          borderTopLeftRadius: 10,
          WebkitBorderTopRightRadius: 10,
        }}
      >
        <h1 className="fw-700 text-grey-900 font-md">
          {i18n.t<string>('SubscriptionModal.title')}
        </h1>
      </Modal.Header>
      <Modal.Body
        className="d-flex bg-white justify-content-center align-items-center flex-column"
        style={{ height: 500 }}
      >
        {!loading && error && (
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              zIndex: 2,
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              backgroundColor: 'white',
              padding: 10,
            }}
          >
            <i className="feather-alert-circle text-warning mb-5 display4-size"></i>
            <p className="text-grey-600 font-xs text-center">
              Ocorreu um problema ao carregar os dados para o pagamento. Por
              favor, tente novamente.
            </p>
            <button
              onClick={fetchUrl}
              className="btn p-2 m-2 rounded-md bg-warning font-xssss fw-700 text-white profile-card-buttons-subscriptions-buttons"
            >
              Tentar Novamente
            </button>
          </div>
        )}
        {loading && (
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              zIndex: 1,
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white',
            }}
          >
            <Spinner className="text-warning" />
          </div>
        )}
        <iframe
          src={url}
          title="ls"
          width="100%"
          height="100%"
          max-width="100%"
          onLoad={() => setLoading(false)}
        />
      </Modal.Body>
      <Modal.Footer className="bg-white d-flex justify-content-center">
        <p className="text-grey-600 fw-600 font-xs text-center">
          {i18n.t<string>('SubscriptionModal.closeModalMessage')}
        </p>
      </Modal.Footer>
    </Modal>
  );
};

export default SubscriptionModal;
