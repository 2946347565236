import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
import { Modal } from 'react-bootstrap';
import { logout } from '../utils/session';
import LeftMenu from '../components/LeftMenu';
import i18n from '../utils/i18n';

import { useNavigate } from 'react-router-dom';
import { isCreator } from '../signals/creator';

const Settings = () => {
  const navigate = useNavigate();

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  useEffect(() => {
    if (!isCreator.value) {
      navigate('/');
      return;
    }
  }, [navigate]);

  return (
    <>
      <Header />
      <LeftMenu />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4 className="mb-4 font-xs text-black fw-700 mont-font mb-lg-5 mb-4 font-md-xs">
                        {i18n.t<string>('Settings.title')}
                      </h4>
                      <ul className="list-inline mb-4">
                        {isCreator.value && (
                          <>
                            <li className="list-inline-item d-block border-bottom me-2">
                              <Link
                                to="/creator-settings"
                                className="pt-2 pb-2 d-flex align-items-center"
                              >
                                <i className="btn-round-md bg-warning text-white feather-settings font-md me-3"></i>{' '}
                                <h4 className="fw-600 font-xsss mb-0 mt-0">
                                  {i18n.t<string>('Settings.creatorSettings')}
                                </h4>
                              </Link>
                            </li>
                            <li className="list-inline-item d-block border-bottom me-2">
                              <Link
                                to="/payout-account"
                                className="pt-2 pb-2 d-flex align-items-center"
                              >
                                <i className="btn-round-md bg-warning text-white feather-dollar-sign font-md me-3"></i>{' '}
                                <h4 className="fw-600 font-xsss mb-0 mt-0">
                                  Conta de Recebimento
                                </h4>
                              </Link>
                            </li>
                          </>
                        )}
                        {/* <li className="list-inline-item d-block border-bottom me-2">
                          <Link
                            to="/account-information"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-warning text-white feather-user font-md me-3"></i>{' '}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                              {i18n.t<string>('Settings.myAccount')}
                            </h4>
                          </Link>
                        </li> */}
                        {/* <li className="list-inline-item d-block border-bottom me-0">
                          <Link
                            to="/change-password"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-warning text-white feather-lock font-md me-3"></i>{' '}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                              {i18n.t<string>('Settings.changePassword')}
                            </h4>
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showLogoutModal}>
          <Modal.Header
            closeButton
            onClick={() => setShowLogoutModal(false)}
            className="bg-white"
            style={{
              border: 0,
              borderRadius: 0,
              padding: 20,
              borderTopLeftRadius: 10,
              WebkitBorderTopRightRadius: 10,
            }}
          />
          <Modal.Body className="bg-white">
            <p className="font-xs text-black text-center">
              {i18n.t<string>('Logout.message')}
            </p>
          </Modal.Body>
          <Modal.Footer className="bg-white d-flex flex-row">
            <div className="form-group mb-1 flex-grow-1">
              <button
                onClick={() => setShowLogoutModal(false)}
                className="form-control text-center text-white fw-600 bg-dark border-0 p-0"
              >
                {i18n.t<string>('Logout.cancel')}
              </button>
            </div>
            <div className="form-group mb-1 flex-grow-1">
              <button
                onClick={() => {
                  logout();
                }}
                className="form-control text-center text-white fw-600 bg-warning border-0 p-0"
              >
                {i18n.t<string>('Logout.confirm')}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      <Appfooter />
    </>
  );
};

export default Settings;
