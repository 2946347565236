import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
import { Spinner } from 'react-bootstrap';
import LeftMenu from '../components/LeftMenu';
import PasswordRequirements from '../components/PasswordRequirements';
import { useNavigate } from 'react-router-dom';
import i18n from '../utils/i18n';

type FormValues = {
  currentpassword: string;
  newpassword: string;
  confirmpassword: string;
};

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordRequirementsMet, setPasswordRequirementsMet] = useState(false);

  const validationSchema = Yup.object().shape({
    currentpassword: Yup.string().required(
      i18n.t('ChangePassword.form.errors.required')
    ),
    newpassword: Yup.string().required(
      i18n.t('ChangePassword.form.errors.required')
    ),
    confirmpassword: Yup.string()
      .oneOf(
        [Yup.ref('newpassword')],
        i18n.t('ChangePassword.form.errors.passwordMustMatch')
      )
      .required(i18n.t('ChangePassword.form.errors.required'))
      .trim(),
  });

  const navigate = useNavigate();

  const onSubmit = async (values: FormValues) => {
    try {
      setLoading(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    isValid,
    errors,
    values,
  } = useFormik({
    initialValues: {
      currentpassword: '',
      newpassword: '',
      confirmpassword: '',
    },
    validationSchema,
    onSubmit,
    validateOnBlur: false,
  });

  return (
    <>
      <Header />
      <LeftMenu />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 border-0 d-flex rounded-3">
                  <button
                    onClick={() => navigate(-1)}
                    className="d-inline-block mt-2 bg-transparent border-0"
                  >
                    <i className="ti-arrow-left font-sm text-black"></i>
                  </button>
                  <h4 className="font-xs text-black fw-600 ms-4 mb-0 mt-2">
                    {i18n.t<string>('ChangePassword.title')}
                  </h4>
                </div>
                <div className="card-body p-5">
                  <form className="mb-3 d-flex flex-column">
                    <label className="mont-font fw-600 font-xsss mb-2">
                      {i18n.t<string>('ChangePassword.currentPassword')}
                    </label>
                    <div className="form-group mb-2 d-flex flex-row align-items-center">
                      <i
                        className="font-sm ti-lock text-grey-500 pe-0"
                        style={{
                          position: 'absolute',
                          left: 60,
                        }}
                      />
                      <input
                        type={showCurrentPassword ? 'text' : 'password'}
                        className="style2-input px-5 form-control text-grey-900 font-xss ls-3"
                        placeholder={i18n.t<string>(
                          'ChangePassword.currentPassword'
                        )}
                        id="currentpassword"
                        name="currentpassword"
                        onChange={(e) => {
                          const text = e.target.value;
                          setFieldValue('currentpassword', text.trim());
                        }}
                        onBlur={handleBlur}
                        disabled={loading}
                        value={values.currentpassword}
                      />
                      <i
                        className={`font-sm ${
                          showCurrentPassword
                            ? 'feather-eye'
                            : 'feather-eye-off'
                        } text-grey-500 pe-0`}
                        style={{
                          position: 'absolute',
                          right: 60,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setShowCurrentPassword(!showCurrentPassword);
                        }}
                      />
                    </div>
                    <span className="text-danger font-xsss mb-2">
                      {touched.currentpassword && errors.currentpassword
                        ? errors.currentpassword
                        : ''}
                    </span>
                    <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                      {i18n.t<string>('ChangePassword.newPassword')}
                    </label>
                    <div className="form-group d-flex flex-row align-items-center mb-2">
                      <i
                        className="font-sm ti-lock text-grey-500 pe-0"
                        style={{
                          position: 'absolute',
                          left: 60,
                        }}
                      />
                      <input
                        type={showNewPassword ? 'text' : 'password'}
                        className="style2-input px-5 form-control text-grey-900 font-xss ls-3"
                        placeholder={i18n.t<string>(
                          'ChangePassword.newPassword'
                        )}
                        id="newpassword"
                        onChange={(e) => {
                          const text = e.target.value;
                          setFieldValue('newpassword', text.trim());
                        }}
                        onBlur={handleBlur}
                        disabled={loading}
                        value={values.newpassword}
                      />
                      <i
                        className={`font-sm ${
                          showNewPassword ? 'feather-eye' : 'feather-eye-off'
                        } text-grey-500 pe-0`}
                        style={{
                          position: 'absolute',
                          right: 60,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setShowNewPassword(!showNewPassword);
                        }}
                      />
                    </div>
                    <span className="text-danger font-xsss mb-2">
                      {touched.newpassword && errors.newpassword
                        ? errors.newpassword
                        : ''}
                    </span>
                    <PasswordRequirements
                      password={values.newpassword}
                      onMeetAllRequirements={() => {
                        setPasswordRequirementsMet(true);
                      }}
                    />
                    <label className="mont-font fw-600 font-xsss mb-2 mt-3">
                      {i18n.t<string>('ChangePassword.confirmPassword')}
                    </label>
                    <div className="form-group mb-2 d-flex flex-row align-items-center">
                      <i
                        className="font-sm ti-lock text-grey-500 pe-0"
                        style={{
                          position: 'absolute',
                          left: 60,
                        }}
                      />
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        className="style2-input px-5 form-control text-grey-900 font-xss ls-3"
                        placeholder={i18n.t<string>(
                          'ChangePassword.confirmPassword'
                        )}
                        id="confirmpassword"
                        name="confirmpassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={loading}
                        value={values.confirmpassword}
                      />
                      <i
                        className={`font-sm ${
                          showConfirmPassword
                            ? 'feather-eye'
                            : 'feather-eye-off'
                        } text-grey-500 pe-0`}
                        style={{
                          position: 'absolute',
                          right: 60,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setShowConfirmPassword(!showConfirmPassword);
                        }}
                      />
                    </div>
                    <span className="text-danger font-xsss mb-2">
                      {touched.confirmpassword && errors.confirmpassword
                        ? errors.confirmpassword
                        : ''}
                    </span>
                  </form>
                  <div className="row">
                    <div className="col-lg-12">
                      {loading ? (
                        <Spinner
                          animation="border"
                          role="status"
                          className="text-warning mt-3"
                        />
                      ) : (
                        <button
                          onClick={() => handleSubmit()}
                          className="bg-warning border-0 text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                          disabled={!isValid || !passwordRequirementsMet}
                          style={
                            !isValid || !passwordRequirementsMet
                              ? { opacity: 0.5 }
                              : { opacity: 1 }
                          }
                        >
                          {i18n.t<string>('ChangePassword.button')}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Appfooter />
    </>
  );
};

export default ChangePassword;
