import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
import LeftMenu from '../components/LeftMenu';
import { Spinner } from 'react-bootstrap';
import { getSubscribers } from '../services/creator';
import SubscriptionStatus from '../enums/SubscriptionStatus';

const Subscribers = () => {
  const [subscribers, setSubscribers] = useState<Subscribers[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadSubscribers = async () => {
      try {
        const subscribers = await getSubscribers();

        const filteredSubscribers = subscribers.filter(
          (subscriber) => subscriber.status !== SubscriptionStatus.NEW
        );

        setSubscribers(filteredSubscribers);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    loadSubscribers();
  }, []);

  return (
    <>
      <Header />
      <LeftMenu />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            {loading ? (
              <div
                className="align-self-center d-flex flex-column justify-content-center align-items-center"
                style={{
                  padding: 100,
                }}
              >
                <Spinner
                  animation="border"
                  role="status"
                  className="text-warning"
                />
              </div>
            ) : (
              <div className="row">
                <div className="col-xl-12">
                  <div className="card-body p-4 w-100 bg-white border-0 d-flex rounded-3">
                    <h4 className="font-xs text-black fw-600 ms-4 mb-0 mt-2">
                      {`Assinantes (${subscribers.length})`}
                    </h4>
                  </div>
                  <div className="row ps-2 pe-2">
                    <div className="col-xl-8 mb-5 d-flex flex-wrap justify-content-center">
                      {subscribers.length ? (
                        subscribers.map((value, index) => (
                          <div
                            className="p-2"
                            style={{
                              width: 150,
                              height: 150,
                            }}
                          >
                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                              <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                                <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1">
                                  <img
                                    src={
                                      value.metadata.profile.avatar ??
                                      'assets/images/user-100x100.png'
                                    }
                                    alt="avater"
                                    className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                                  />
                                </figure>
                                <div className="clearfix w-100"></div>
                                <h4 className="fw-700 font-xsss mt-3 mb-0">
                                  {value.metadata.username}{' '}
                                </h4>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="align-self-center d-flex flex-column justify-content-center align-items-center p-5">
                          <img
                            src="assets/images/logo-blank.png"
                            alt="avater"
                            style={{
                              width: 50,
                              height: 50,
                            }}
                          />
                          <span className="text-grey-500 m-2 font-xss text-center">
                            Nenhum assinante no momento
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Appfooter />
    </>
  );
};

export default Subscribers;
