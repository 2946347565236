import { useEffect, useState } from "react";

const useSwipeDirection = (onSwipeLeft: () => void, onSwipeRight: () => void, threshold = 50) => {
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const handleTouchStart = (event: any) => {
      setTouchStartX(event.touches[0].clientX);
      setTouchEndX(null);
    };

    const handleTouchMove = (event: any) => {
      setTouchEndX(event.touches[0].clientX);
    };

    const handleTouchEnd = () => {
      if (!touchStartX || !touchEndX) return;

      const distance = touchEndX - touchStartX;
      
      if (distance > threshold) {
        setPosition(window.innerWidth); // Move right: ;
        onSwipeRight && onSwipeRight();
      } else if (distance < -threshold) {
        setPosition(-window.innerWidth); // Move left
        onSwipeLeft && onSwipeLeft();
      }

      // Reset position after a brief delay (for smooth reset)
      setTimeout(() => setPosition(0), 700); // Reset after 300ms
    };

    document.addEventListener("touchstart", handleTouchStart);
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleTouchEnd);

    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, [touchStartX, touchEndX, onSwipeLeft, onSwipeRight, threshold]);

  return position;
};

export default useSwipeDirection;
