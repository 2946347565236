import { signal } from '@preact/signals';

export const myProfile = signal<MyProfile>({
  profile: {
    id: '',
    userId: '',
    avatar: '',
    cover: '',
    about: '',
    createdAt: '',
    updatedAt: '',
  },
  creatorId: '',
});
