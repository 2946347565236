import React, { useCallback, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';

import Editor from './Editor';
import Preview from './Preview';
import { myProfile } from '../../signals/profile';

type Props = {
  isVisible: boolean;
  onClose: () => void;
};

const ProfilePhotoModal: React.FC<Props> = ({ isVisible, onClose }) => {
  const [newPhoto, setNewPhoto] = useState(null);

  const [showPhotoEditor, setShowPhotoEditor] = useState(false);

  const saveNewProfilePhoto = useCallback((editedPhoto: any) => {
    if (myProfile.value) {
      myProfile.value.profile.avatar = editedPhoto;
      setShowPhotoEditor(false);
      onClose();
    }
  }, [onClose]);

  const onAddNewPhoto = useCallback((photo: any) => {
    setNewPhoto(photo);
    setShowPhotoEditor(true);
  }, []);

  const onCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const modalContent = useMemo(() => {
    if (showPhotoEditor) {
      return (
        <Editor
          onSaveNewProfilePhoto={saveNewProfilePhoto}
          onClose={() => setShowPhotoEditor(false)}
          newProfilePhotoToEdit={newPhoto}
        />
      );
    }
    return (
      <Preview
        onAddNewPhoto={onAddNewPhoto}
        onClose={onCloseModal}
      />
    );
  }, [showPhotoEditor, onAddNewPhoto, onCloseModal, saveNewProfilePhoto, newPhoto]);

  return (
    <Modal show={isVisible}>
      {modalContent}
    </Modal>
  );
};

export default ProfilePhotoModal;
