import React, { useEffect } from 'react';
import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
import Createpost from '../components/Createpost';
import MyProfileCard from '../components/MyProfileCard';
import MyProfileDetail from '../components/MyProfileDetail';
import NewFeedScrollPage from '../components/Utility/NewFeedScrollPage';
import NewFeed from '../components/Utility/NewFeed';
import LeftMenu from '../components/LeftMenu';
import newUseFeed from '../hooks/newUseFeed';
import { session } from '../signals/session';
import BecomeCreatorBanner from '../components/BecomeCreatorBanner';
import { isCreator } from '../signals/creator';

const MyProfile = () => {

  const {
    feed,
    isFeedLoading,
    isFeedNextPageLoading,
    loadPage,
    onDeletePost,
    onPost,
    fetchFeedNextPage,
  } = newUseFeed(true);

  useEffect(() => {
    loadPage(session.value.username);
  }, [loadPage]);

  const onReachOutBottom = () => {
    fetchFeedNextPage(session.value.username);
  };

  return (
    <>
      <Header />
      <LeftMenu />
      <NewFeedScrollPage
        loading={isFeedLoading}
        footerLoading={isFeedNextPageLoading}
        onReachOutBottom={onReachOutBottom}
        feed={feed}
      >
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12 mb-3">
                <MyProfileCard />
              </div>
              <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                <MyProfileDetail />
              </div>
              <div className="col-xl-8 col-xxl-9 col-lg-8">
                {isCreator.value ? (
                  <>
                    <Createpost onPost={onPost} />
                    <div className="mb-5">
                      <NewFeed
                        isLoading={isFeedLoading}
                        isNextPageLoading={isFeedNextPageLoading}
                        feed={feed}
                        onDeletePost={onDeletePost}
                      />
                    </div>
                  </>
                ) : (
                  <BecomeCreatorBanner />
                )}
              </div>
            </div>
          </div>
        </div>
      </NewFeedScrollPage>
      <Appfooter />
    </>
  );
};

export default MyProfile;
