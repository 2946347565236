import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Select from 'react-select';

import Header from '../components/Header';
import Appfooter from '../components/Appfooter';

import LeftMenu from '../components/LeftMenu';
import { gendersData } from '../utils/data';
import i18n from '../utils/i18n';
import { Form, Spinner } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { becomeCreator, getCreator } from '../services/creator';
import { format, parse } from 'date-fns';
import { creator, isCreator } from '../signals/creator';
import CreatorAccountStatus from '../enums/CreatorAccountStatus';
import { useNavigate } from 'react-router-dom';
import { validateCPF, validateDate, isMinimumAge } from '../utils/validation';
import { Modal } from 'react-bootstrap';

const Creator = () => {
  const navigate = useNavigate();

  const scrollableDivRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(false);

  const [acceptedTerms, setAcceptTerms] = useState(false);

  const [showExistingAccountErrorModal, setShowExistingAccountErrorModal] =
    useState(false);

  useEffect(() => {
    if (isCreator.value) {
      navigate('/');
      return;
    }
  }, [navigate]);

  useEffect(() => {
    if (
      creator.value &&
      creator.value.status === CreatorAccountStatus.CREATED
    ) {
      setPending(true);
    }
    setLoading(false);
  }, []);

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required(
      i18n.t('BecomeCreator.form.errors.required')
    ),
    lastname: Yup.string().required(
      i18n.t('BecomeCreator.form.errors.required')
    ),
    birthdate: Yup.string()
      .required(i18n.t('BecomeCreator.form.errors.required'))
      .test('is-valid-date', i18n.t('BecomeCreator.form.errors.invalidDate'), validateDate)
      .test('is-adult', i18n.t('BecomeCreator.form.errors.mustBeAdult'), (value) => {
        return isMinimumAge(value || '', 18);
      }),
    gender: Yup.string().required(i18n.t('BecomeCreator.form.errors.required')),
    identityDocument: Yup.string()
      .required(i18n.t('BecomeCreator.form.errors.required'))
      .test('is-valid-cpf', i18n.t('BecomeCreator.form.errors.invalidCpf'), (value) => validateCPF(value || '')),
    phoneNumber: Yup.string().required(
      i18n.t('BecomeCreator.form.errors.required')
    ),
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldError,
    isValid,
    values,
    errors,
  } = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      birthdate: '',
      gender: '',
      identityDocument: '',
      phoneNumber: '',
    },
    onSubmit,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema,
    initialErrors: {
      firstname: i18n.t('BecomeCreator.form.errors.required'),
      lastname: i18n.t('BecomeCreator.form.errors.required'),
      birthdate: i18n.t('BecomeCreator.form.errors.required'),
      gender: i18n.t('BecomeCreator.form.errors.required'),
      identityDocument: i18n.t('BecomeCreator.form.errors.required'),
      phoneNumber: i18n.t('BecomeCreator.form.errors.required'),
    },
  });

  async function onSubmit(values: CreatorRegistration) {
    try {
      setLoading(true);

      const parsedDate = parse(values.birthdate, 'dd/MM/yyyy', new Date());
      const formattedDate = format(parsedDate.toISOString(), 'yyyy-MM-dd');

      const creatorData: CreatorRegistration = {
        ...values,
        birthdate: formattedDate,
        phoneNumber: values.phoneNumber.replace(/\D/g, ''),
        identityDocument: values.identityDocument.replace(/\D/g, ''),
      };

      await becomeCreator(creatorData);

      const creatorResp = await getCreator();

      if (creatorResp) {
        creator.value = creatorResp;
      }

      setPending(true);
    } catch (error: any) {
      if (error.response.status === 409) {
        setFieldError('identityDocument', 'CPF já cadastrado');
        setShowExistingAccountErrorModal(true);
      }
    } finally {
      setLoading(false);
    }
  }

  const genders = useMemo(() => {
    const genders: { label: string; value: string }[] = [];

    for (const gender of gendersData) {
      genders.push({
        label: i18n.t(`Genders.${gender.value}`),
        value: gender.value,
      });
    }

    return genders;
  }, []);

  return (
    <>
      <Header />
      <LeftMenu />
      <div className="main-content right-chat-active" ref={scrollableDivRef}>
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 border-0 d-flex rounded-3">
                  <h4 className="font-xs text-black fw-600 ms-4 mb-0 mt-2">
                    {i18n.t<string>('BecomeCreator.title')}
                  </h4>
                </div>
                {pending ? (
                  <div className="d-flex justify-content-center align-items-center flex-column p-5">
                    {/* <i className="feather-loader display5-size text-warning mb-5 mt-5" /> */}
                    <img
                      src={'assets/images/creator-pending-analysis.png'}
                      alt="avatar"
                      style={{ width: 200, height: 200 }}
                      className="shadow-sm rounded-circle mb-4"
                    />
                    <p className="text-grey-500 m-2 font-xs text-center">
                      Obrigado por seu interesse em se tornar um criador
                      conosco! Estamos analisando seus dados e em breve
                      entraremos em contato com você.
                    </p>
                  </div>
                ) : (
                  <div className="card-body p-lg-5 p-4 w-100 border-0 d-flex justify-content-center">
                    {loading ? (
                      <Spinner
                        animation="border"
                        role="status"
                        className="text-warning mt-3"
                      />
                    ) : (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                                {i18n.t<string>('BecomeCreator.form.firstName')}
                              </label>
                              <input
                                type="text"
                                className="form-control font-xss"
                                disabled={loading}
                                value={values.firstname}
                                placeholder={i18n.t<string>(
                                  'BecomeCreator.form.firstName'
                                )}
                                name="firstname"
                                id="firstname"
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              <span className="text-danger font-xsss fw-500">
                                {errors.firstname ? errors.firstname : ''}
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                                {i18n.t<string>('BecomeCreator.form.lastName')}
                              </label>
                              <input
                                type="text"
                                className="form-control font-xss"
                                disabled={loading}
                                value={values.lastname}
                                placeholder={i18n.t<string>(
                                  'BecomeCreator.form.lastName'
                                )}
                                name="lastname"
                                id="lastname"
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              <span className="text-danger font-xsss fw-500">
                                {errors.lastname ? errors.lastname : ''}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                                {i18n.t<string>(
                                  'BecomeCreator.form.dateOfBirth'
                                )}
                              </label>
                              <InputMask
                                className="form-control font-xss"
                                mask="99/99/9999"
                                value={values.birthdate}
                                placeholder="DD/MM/YYYY"
                                name="birthdate"
                                id="birthdate"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                              <span className="text-danger font-xsss fw-500">
                                {errors.birthdate ? errors.birthdate : ''}
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                                {i18n.t<string>('BecomeCreator.form.gender')}
                              </label>
                              <Select
                                onChange={(gender: any) => {
                                  setFieldValue('gender', gender.value);
                                }}
                                className="font-xss"
                                value={{
                                  label: values.gender
                                    ? i18n.t(`Genders.${values.gender}`)
                                    : '',
                                  value: values.gender,
                                }}
                                options={genders}
                              />
                              <span className="text-danger font-xsss fw-500">
                                {errors.gender ? errors.gender : ''}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss mb-2">
                                {i18n.t<string>('BecomeCreator.form.cpf')}
                              </label>
                              <InputMask
                                className="form-control font-xss"
                                mask="999.999.999-99"
                                placeholder="000.000.000-00"
                                value={values.identityDocument}
                                name="identityDocument"
                                id="identityDocument"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                              <span className="text-danger font-xsss fw-500">
                                {errors.identityDocument ? errors.identityDocument : ''}
                              </span>
                            </div>
                          </div>
                        </div>
                        <h4 className="font-xs text-black fw-600 mb-4 mt-4">
                          Contato
                        </h4>
                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss mb-2 mt-2">
                                Telefone/Whatsapp
                              </label>
                              <InputMask
                                className="form-control font-xss"
                                mask="(99) 99999-9999"
                                value={values.phoneNumber}
                                placeholder="(00) 00000-0000"
                                name="phoneNumber"
                                id="phoneNumber"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                              <span className="text-danger font-xsss fw-500">
                                {errors.phoneNumber ? errors.phoneNumber : ''}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="mt-3 border-0 bg-transparent d-flex flex-row">
                          <Form.Check
                            type="checkbox"
                            checked={acceptedTerms}
                            className="me-2 mt-1"
                            onChange={(e) => setAcceptTerms(!acceptedTerms)}
                          />
                          <p className="font-xsss mt-1">
                            {i18n.t<string>('BecomeCreator.acceptTermsText1')}{' '}
                            <span
                              className="text-warning fw-700"
                              style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                              }}
                              onClick={() => {
                                window.open(
                                  'https://pages.silksecret.me/docs/termos',
                                  '_blank'
                                );
                              }}
                            >
                              {i18n.t<string>('BecomeCreator.acceptTermsText2')}
                            </span>{' '}
                            {i18n.t<string>('BecomeCreator.acceptTermsText3')}{' '}
                            <span
                              className="text-warning fw-700"
                              onClick={() => {
                                window.open(
                                  'https://pages.silksecret.me/docs/privacidade',
                                  '_blank'
                                );
                              }}
                              style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                              }}
                            >
                              {i18n.t<string>('BecomeCreator.acceptTermsText4')}
                            </span>{' '}
                            {i18n.t<string>('BecomeCreator.acceptTermsText5')}
                          </p>
                        </div>
                        <div className="row w-100 d-flex justify-content-center">
                          <div className="col-lg-12">
                            <button
                              type="submit"
                              className="bg-warning border-0 text-center text-white font-xsss fw-600 p-3 rounded-3 d-inline-block"
                              disabled={!isValid || !acceptedTerms}
                              style={
                                !isValid || !acceptedTerms
                                  ? { opacity: 0.5 }
                                  : { opacity: 1 }
                              }
                            >
                              Solicitar ser criador
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showExistingAccountErrorModal}>
        <Modal.Header
          closeButton
          onClick={() => setShowExistingAccountErrorModal(false)}
          className="bg-white"
          style={{
            border: 0,
            borderRadius: 0,
            padding: 20,
            borderTopLeftRadius: 10,
            WebkitBorderTopRightRadius: 10,
          }}
        />
        <Modal.Body className="bg-white">
          <p className="font-xs text-black text-center">
            Já existe uma solicitação ou conta registrada com esse CPF.
          </p>
        </Modal.Body>
        <Modal.Footer className="bg-white d-flex flex-row">
          <div className="form-group mb-1 flex-grow-1">
            <button
              onClick={() => setShowExistingAccountErrorModal(false)}
              className="form-control text-center text-white fw-600 bg-warning border-0 p-0"
            >
              OK
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Appfooter />
    </>
  );
};

export default Creator;
