import React from 'react';

const ErrorPage = () => {
  return (
    <>
      <div className="main-content pt-0 bg-white ps-0 pe-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 text-center default-page vh-100 align-items-center d-flex">
              <div className="card border-0 text-center d-block p-0">
                <img
                  src="assets/images/logo.png"
                  alt="icon"
                  className="w200 mb-4 ms-auto me-auto pt-md-5"
                />
                <h1 className="fw-700 text-grey-900 display3-size display4-md-size">
                  Oops! Alguma coisa deu errado.
                </h1>
                <p className="text-grey-500 font-xss mt-5 mb-5">
                  A página que você está procurando não está disponível
                </p>
                <a
                  href="/"
                  className="p-3 w175 bg-dark text-white d-inline-block text-center fw-600 font-xssss rounded-3 text-uppercase ls-3"
                >
                 Voltar para o Início
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
