import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
import { Spinner } from 'react-bootstrap';
import LeftMenu from '../components/LeftMenu';

import CurrencyInput from 'react-currency-input-field';
import i18n from '../utils/i18n';
import { getPlansByCreatorId, savePlan } from '../services/creator';
import { creator, isCreator } from '../signals/creator';
import { Modal } from 'react-bootstrap';

const validationSchema = Yup.object().shape({
  monthlySubscription: Yup.string().required('Required'),
});

type FormValues = {
  instagramAccount: string;
  xAccount: string;
  tiktokAccount: string;
  site: string;
  monthlySubscription: string;
};

const CreatorSettings = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState<Plan>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    if (!isCreator.value) {
      navigate('/');
      return;
    }
  }, [navigate]);

  useEffect(() => {
    const loadPlan = async () => {
      try {
        setLoading(true);
        if (isCreator.value && creator.value) {
          const plans = await getPlansByCreatorId(creator.value.id);

          if (plans.length) {
            setPlan(plans[0]);
          }
        }
      } catch (error) {
        console.log('Error on load plan');
      } finally {
        setLoading(false);
      }
    };

    loadPlan();
  }, []);

  const onSubmit = async (values: FormValues) => {
    try {
      setLoading(true);

      const formattedValue = values.monthlySubscription.replace(',', '.');

      await savePlan(Number(formattedValue) * 100);

      window.location.reload();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const {
    handleSubmit,
    setFieldValue,
    isValid,
    values,
    errors,
    setFieldError,
  } = useFormik({
    initialValues: {
      instagramAccount: '',
      xAccount: '',
      tiktokAccount: '',
      site: '',
      monthlySubscription: '',
    },
    validationSchema,
    onSubmit,
    validateOnBlur: false,
  });

  return (
    <>
      <Header />
      <LeftMenu />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                {loading ? (
                  <div
                    style={{
                      height: 500,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Spinner
                      animation="border"
                      role="status"
                      className="text-warning mt-3"
                    />
                  </div>
                ) : (
                  <>
                    <div className="card-body p-4 w-100 border-0 d-flex rounded-3">
                      <button
                        onClick={() => navigate(-1)}
                        className="d-inline-block mt-2 bg-transparent border-0"
                      >
                        <i className="ti-arrow-left font-sm text-black"></i>
                      </button>
                      <h4 className="font-xs text-black fw-600 ms-4 mb-0 mt-2">
                        {i18n.t<string>('CreatorSettings.title')}
                      </h4>
                    </div>
                    <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                      <form>
                        <div className="row mb-5">
                          <div className="form-group">
                            <div className="d-flex flex-row justify-content-center">
                              <label className="mont-font fw-600 font-xss mb-4 me-2">
                                {i18n.t<string>(
                                  'CreatorSettings.monthlySubscription'
                                )}
                              </label>
                            </div>
                            <div className="d-flex flex-row justify-content-center">
                              <label className="mont-font fw-600 font-xs me-2">
                                R$
                              </label>
                              <div className="d-flex flex-column">
                                <CurrencyInput
                                  name="monthlySubscription"
                                  onValueChange={(value) => {
                                    setFieldValue('monthlySubscription', value);
                                  }}
                                  value={
                                    plan
                                      ? plan.value / 100
                                      : values.monthlySubscription
                                  }
                                  maxLength={10}
                                  decimalSeparator=","
                                  allowNegativeValue={false}
                                  groupSeparator="."
                                  placeholder="0,00"
                                  disabled={plan ? true : false}
                                  style={{
                                    fontSize: 20,
                                    padding: 5,
                                    fontWeight: 700,
                                    color: '#6c757d',
                                    textAlign: 'center',
                                    borderWidth: 0,
                                    borderBottomWidth: 1,
                                    borderBottomColor: '#ced4da',
                                  }}
                                />
                                <span className="text-danger font-xsss fw-500 mt-2 text-center">
                                  {errors.monthlySubscription
                                    ? errors.monthlySubscription
                                    : ''}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {!plan ? (
                          <div>
                            <p className="font-xsss fw-600 mt-2 text-center">
                              <span className="text-warning pe-1">
                                Atenção:
                              </span>
                              Para realizar a alteração do valor do plano, será
                              necessário entrar em contato com o nosso suporte
                              em:{' '}
                              <span className="text-warning">
                                help@silksecret.me
                              </span>
                            </p>
                            <div className="d-flex justify-content-center align-items-center">
                              <button
                                onClick={() => {
                                  const formattedValue =
                                    values.monthlySubscription.replace(
                                      ',',
                                      '.'
                                    );

                                  if (Number(formattedValue) < 35) {
                                    setFieldError(
                                      'monthlySubscription',
                                      'O valor mínimo é de R$ 35,00'
                                    );
                                    return;
                                  }
                                  setShowConfirmationModal(true);
                                }}
                                disabled={!isValid}
                                type="button"
                                className="bg-warning border-0 text-center text-white font-xsss fw-600 p-3 w175 rounded-3"
                                style={
                                  !isValid ? { opacity: 0.5 } : { opacity: 1 }
                                }
                              >
                                Salvar Plano
                              </button>
                            </div>
                          </div>
                        ) : (
                          <p className="font-xsss fw-600 mt-2">
                            Para realizar a alteração do valor do plano, entre
                            em contato com o nosso suporte em:{' '}
                            <span className="text-warning">
                              help@silksecret.me
                            </span>
                          </p>
                        )}
                      </form>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showConfirmationModal}>
        <Modal.Header
          closeButton
          onClick={() => setShowConfirmationModal(false)}
          className="bg-white"
          style={{
            border: 0,
            borderRadius: 0,
            padding: 20,
            borderTopLeftRadius: 10,
            WebkitBorderTopRightRadius: 10,
          }}
        />
        <Modal.Body className="bg-white">
          <p className="font-xs text-black text-center">
            Deseja realmente confirmar o plano de{' '}
            <span className="fw-600 font-md">{`R$ ${values.monthlySubscription}`}</span>{' '}
            por mês ?
          </p>
          <p className="font-xsss fw-600 mt-2">
            Para realizar a alteração do valor do plano, será necessário entrar
            em contato com o nosso suporte em:{' '}
            <span className="text-warning">help@silksecret.me</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="bg-white d-flex flex-row">
          <div className="form-group mb-1 flex-grow-1">
            <button
              onClick={() => setShowConfirmationModal(false)}
              className="form-control text-center text-white fw-600 bg-dark border-0 p-0"
            >
              Não
            </button>
          </div>
          <div className="form-group mb-1 flex-grow-1">
            <button
              onClick={() => {
                setShowConfirmationModal(false);
                handleSubmit();
              }}
              className="form-control text-center text-white fw-600 bg-warning border-0 p-0"
            >
              Confirmar
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Appfooter />
    </>
  );
};

export default CreatorSettings;
