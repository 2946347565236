import React, { useEffect, useState } from 'react';

import { verifyEmail } from '../services/auth';

import { useNavigate } from 'react-router-dom';

import { Spinner } from 'react-bootstrap';

import { getUrlParamValue } from '../utils/data';

const VerifyAccount = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [titleMessage, setTitleMessage] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const verifyAccount = async () => {
      try {
        setLoading(true);
        setTitleMessage('Verificando sua conta');

        const token = getUrlParamValue('token');

        if (token) {
          await verifyEmail(token);

          setTitleMessage('Conta verificada!');
          return;
        }
        setTitleMessage('Ops!');
        setErrorMessage('Ocorreu um erro ao verificar o e-mail.');
      } catch (error) {
        setTitleMessage('Ops!');
        setErrorMessage('Ocorreu um erro ao verificar o e-mail.');
      } finally {
        setLoading(false);
      }
    };
    verifyAccount();
  }, []);

  return (
    <>
      <div className="main-wrap">
        <div className="row">
          <div className="col-xl-5 justify-content-center align-items-center d-flex">
            <img
              src="assets/images/logo-login.png"
              className="login-logo"
              alt=""
            />
          </div>
          <div className="col-xl-7 login-block align-items-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div className="card-body rounded-0 text-center">
                <h2 className="fw-700 font-md display2-md-size mb-5">
                  {titleMessage}
                </h2>
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <Spinner
                      animation="border"
                      role="status"
                      className="text-warning"
                    />
                  </div>
                ) : (
                  <div className="col-sm-12 p-0 text-center">
                    {errorMessage.length ? (
                      <span className="text-grey-900 font-xs fw-500">
                        {errorMessage}
                      </span>
                    ) : (
                      <button
                        onClick={() => navigate('/login')}
                        className="form-control text-center text-white fw-600 bg-warning border-0 p-0"
                      >
                        Entrar
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyAccount;
