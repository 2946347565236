import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import i18n from '../../utils/i18n';

const menu: {
  section: {
    id: string;
    items: { id: string; icon: string; route: string, action?: () => void }[];
  };
}[] = [
  {
    section: {
      id: '',
      items: [
        {
          id: 'myprofile',
          icon: 'feather-user',
          route: '/myprofile',
        },
        { id: 'popular', icon: 'feather-trending-up', route: '/' },
        { id: 'feed', icon: 'feather-unlock', route: '/feed' },
      ],
    },
  },
  {
    section: {
      id: 'general',
      items: [
        {
          id: 'following',
          icon: 'feather-user-check',
          route: '/following',
        },
        {
          id: 'subscriptions',
          icon: 'feather-bookmark',
          route: '/subscriptions',
        },
      ],
    },
  },
  {
    section: {
      id: 'creator',
      items: [
        {
          id: 'payout',
          icon: 'feather-dollar-sign',
          route: '/payout',
        },
        {
          id: 'subscribers',
          icon: 'feather-heart',
          route: '/subscribers',
        },
      ],
    },
  },
  {
    section: {
      id: 'account',
      items: [
        {
          id: 'settings',
          icon: 'feather-settings',
          route: '/settings',
        },
      ],
    },
  },
  {
    section: {
      id: 'support',
      items: [
        {
          id: 'support',
          icon: 'feather-mail',
          route: 'mailto:help@silksecret.me',
        },
      ],
    },
  },
];

const LeftDefaultMenu = () => {
  const [selectedItemId, setSelectedItemId] = useState('myprofile');

  useEffect(() => {
    const pathname = window.location.pathname.replace('/', '');

    setSelectedItemId(pathname ? pathname : 'popular');
  }, []);

  return (
    <div className="navigation scroll-bar mt-3">
      <div className="container ps-0 pe-0">
        <div className="nav-content">
          {menu.map((menuItem) => {
            return (
              <div
                key={menuItem.section.id}
                className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2"
              >
                <div className="nav-caption fw-600 font-xsss text-grey-500">
                  {menuItem.section.id &&
                    i18n.t<string>(`Menu.title.${menuItem.section.id}`)}
                </div>
                <ul className="mb-1 top-content">
                  {menuItem.section.items.map((sectionItem) => {
                    return (
                      <li key={sectionItem.id} id={sectionItem.id}>
                        <Link
                          to={sectionItem.route}
                          className="open-font h-auto pt-2 pb-2"
                          id={sectionItem.id}
                        >
                          <i
                            className={`font-sm ${sectionItem.icon} me-3 ${
                              selectedItemId === sectionItem.id
                                ? 'text-warning'
                                : 'text-grey-500'
                            }`}
                          ></i>
                          <h4
                            className={`fw-600 ${
                              selectedItemId === sectionItem.id
                                ? 'text-warning'
                                : 'text-grey-500'
                            } font-xsss mb-0 mt-0`}
                          >
                            {i18n.t<string>(`Menu.${sectionItem.id}`)}
                          </h4>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LeftDefaultMenu;
