// import Payment from 'payment';
import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  parseISO,
  differenceInYears,
} from 'date-fns';
import i18n from './i18n';

export const getUrlParamValue = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search);

  const paramValue = urlParams.get(param);

  return paramValue;
};

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

// TODO REMOVE

// export function formatCreditCardNumber(value?: any) {
//   if (!value) {
//     return value;
//   }

//   const issuer = Payment.fns.cardType(value);
//   const clearValue = clearNumber(value);
//   let nextValue;

//   switch (issuer) {
//     case 'amex':
//       nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
//         4,
//         10
//       )} ${clearValue.slice(10, 15)}`;
//       break;
//     case 'dinersclub':
//       nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
//         4,
//         10
//       )} ${clearValue.slice(10, 14)}`;
//       break;
//     default:
//       nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
//         4,
//         8
//       )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
//       break;
//   }

//   return nextValue.trim();
// }

// TODO REMOVE

// export function formatCVC(
//   value: string,
//   prevValue?: any,
//   allValues = { number: '' }
// ) {
//   const clearValue = clearNumber(value);
//   let maxLength = 4;

//   if (allValues.number) {
//     const issuer = Payment.fns.cardType(allValues.number);
//     maxLength = issuer === 'amex' ? 4 : 3;
//   }

//   return clearValue.slice(0, maxLength);
// }

export function formatExpirationDate(value: any) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatFormData(data: any) {
  return Object.keys(data).map((d) => `${d}: ${data[d]}`);
}

export const formatCurrency = (value: number) => {
  if (value) {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    });
  }

  return 'R$ 0,00';
};

export const formatPostDate = (createdAt: string) => {
  if (!createdAt) return '';

  const parsedDate = parseISO(createdAt);
  const currentDate = new Date();

  const minutesDiff = differenceInMinutes(currentDate, parsedDate);
  const hoursDiff = differenceInHours(currentDate, parsedDate);
  const daysDiff = differenceInDays(currentDate, parsedDate);

  if (minutesDiff < 1) {
    return i18n.t('PostView.time.now');
  } else if (minutesDiff === 1) {
    return `${minutesDiff} ${i18n.t('PostView.time.minuteSingle')}`;
  } else if (minutesDiff < 60) {
    return `${minutesDiff} ${i18n.t('PostView.time.minutePlural')}`;
  } else if (hoursDiff < 24) {
    return `${hoursDiff} ${i18n.t('PostView.time.hoursPlural')}`;
  } else if (daysDiff === 1) {
    return `${daysDiff} ${i18n.t('PostView.time.daySingle')}`;
  } else if (daysDiff > 31) {
    return `${Math.round(daysDiff / 30)} ${i18n.t('PostView.time.monthPlural')}`;
  } else {
    return `${daysDiff} ${i18n.t('PostView.time.dayPlural')}`;
  }
};

export const formatLikes = (likes: number) => {
  if (!likes) return '';

  return likes;
};

export const countries = [
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Brazil', value: 'BR' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cabo Verde', value: 'CV' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo, Democratic Republic of the', value: 'CD' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Eswatini', value: 'SZ' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Greece', value: 'GR' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: "Lao People's Democratic Republic", value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia (Federated States of)', value: 'FM' },
  { label: 'Moldova', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'New Zealand', value: 'NZ' },
];

export const gendersData = [
  { value: 'male' },
  { value: 'female' },
  { value: 'none' }
];

export const bankingInstitutionsData = [
  { value: 'Administradora de Consórcios Sicredi LTDA' },
  { value: 'Agoracred S/A Sociedade de Crédito, Financiamento e Investimento' },
  { value: '246 - Banco ABC Brasil S.A.' },
  { value: '75 - Banco ABN AMRO S.A.' },
  { value: '299 - Banco Afinz S.A. Banco Múltiplo' },
  { value: '121 - Banco Agibank S.A.' },
  { value: 'Banco Alfa de Investimentos S.A.' },
  { value: '25 - Banco Alfa S.A.' },
  { value: '641 - Banco Alvorada S.A.' },
  { value: '65 - Banco Andbank (Brasil) S.A.' },
  { value: '213 - Banco Arbi S.A.' },
  { value: '96 - Banco B3 S.A.' },
  { value: '24 - Banco BANDEPE S.A.' },
  { value: '330 - Banco Bari de Investimentos e Financiamentos S/A' },
  { value: '318 - Banco BMG S.A.' },
  { value: '752 - Banco BNP Paribas Brasil S.A.' },
  { value: '107 - Banco BOCOM BBM S.A.' },
  { value: '63 - Banco Bradescard S.A.' },
  { value: '36 - Banco Bradesco BBI S.A.' },
  { value: '122 - Banco Bradesco BERJ S.A.' },
  { value: '204 - Banco Bradesco Cartões S.A.' },
  { value: '394 - Banco Bradesco Financiamentos S.A.' },
  { value: '237 - Banco Bradesco S.A.' },
  { value: '218 - Banco BS2 S.A.' },
  { value: '208 - Banco BTG Pactual S.A.' },
  { value: '626 - Banco C6 Consignado S.A.' },
  { value: 'Banco C6 S.A.' },
  { value: '473 - Banco Caixa Geral - Brasil S.A.' },
  { value: '40 - Banco Cargill S.A.' },
  { value: 'Banco Caterpillar S.A.' },
  { value: '266 - Banco Cédula S.A.' },
  { value: 'Banco Central do Brasil' },
  { value: '739 - Banco Cetelem S.A.' },
  { value: '233 - Banco Cifra S.A.' },
  { value: '745 - Banco Citibank S.A.' },
  { value: '241 - Banco Clássico S.A.' },
  { value: 'Banco CNH Industrial Capital S.A.' },
  { value: '756 - Banco Cooperativo Sicoob S.A.' },
  { value: '748 - Banco Cooperativo Sicredi S.A.' },
  { value: '222 - Banco Credit Agricole Brasil S.A.' },
  { value: '505 - Banco Credit Suisse (Brasil) S.A.' },
  { value: '69 - Banco Crefisa S.A.' },
  { value: 'Banco CSF S.A.' },
  { value: '3 - Banco da Amazônia S.A.' },
  { value: '83 - Banco da China Brasil S.A.' },
  { value: '707 - Banco Daycoval S.A.' },
  { value: '51 - Banco de Desenvolvimento do Espírito Santo S.A.' },
  { value: 'Banco de Investimento Credit Suisse (Brasil) S.A.' },
  { value: '300 - Banco de La Nacion Argentina' },
  { value: '495 - Banco de La Provincia de Buenos Aires' },
  { value: '494 - Banco de La Republica Oriental del Uruguay' },
  { value: 'Banco de Lage Landen Brasil S.A.' },
  { value: '654 - Banco Digimais S.A.' },
  { value: 'Banco Digio S.A.' },
  { value: '1 - Banco do Brasil S.A.' },
  { value: '47 - Banco do Estado de Sergipe S.A.' },
  { value: '37 - Banco do Estado do Pará S.A.' },
  { value: '41 - Banco do Estado do Rio Grande do Sul S.A.' },
  { value: '4 - Banco do Nordeste do Brasil S.A.' },
  { value: '265 - Banco Fator S.A.' },
  { value: '224 - Banco Fibra S.A.' },
  { value: 'Banco Fidis S.A.' },
  { value: '94 - Banco Finaxis S.A.' },
  { value: 'Banco Ford S.A.' },
  { value: '125 - Banco Genial S.A.' },
  { value: 'Banco GM S.A.' },
  { value: '612 - Banco Guanabara S.A.' },
  { value: 'Banco Honda S.A.' },
  { value: '269 - Banco HSBC S.A.' },
  { value: 'Banco IBM S.A.' },
  { value: '12 - Banco Inbursa S.A.' },
  { value: '604 - Banco Industrial do Brasil S.A.' },
  { value: '77 - Banco Inter S.A.' },
  { value: '249 - Banco Investcred Unibanco S.A.' },
  { value: '184 - Banco Itaú BBA S.A.' },
  { value: '29 - Banco Itaú Consignado S.A.' },
  { value: 'Banco Itaú Veículos S.A.' },
  { value: '479 - Banco ItauBank S.A' },
  { value: 'Banco Itaucard S.A.' },
  { value: '376 - Banco J. P. Morgan S.A.' },
  { value: '74 - Banco J. Safra S.A.' },
  { value: '217 - Banco John Deere S.A.' },
  { value: '76 - Banco KDB S.A.' },
  { value: '757 - Banco KEB HANA do Brasil S.A.' },
  { value: 'Banco Komatsu S.A.' },
  { value: '630 - Banco Letsbank S.A.' },
  { value: 'Banco Losango S.A. - Banco Múltiplo' },
  { value: '600 - Banco Luso Brasileiro S.A.' },
  { value: '243 - Banco Master S.A.' },
  { value: 'Banco Mercantil de Investimentos S.A.' },
  { value: '389 - Banco Mercantil do Brasil S.A.' },
  { value: 'Banco Mercedes-Benz do Brasil S.A.' },
  { value: '370 - Banco Mizuho do Brasil S.A.' },
  { value: '746 - Banco Modal S.A.' },
  { value: 'Banco Moneo S.A.' },
  { value: '66 - Banco Morgan Stanley S.A.' },
  { value: '456 - Banco MUFG Brasil S.A.' },
  { value: '7 - Banco Nacional de Desenvolvimento Econômico e Social - BNDES' },
  { value: '79 - Banco Original do Agronegócio S.A.' },
  { value: '212 - Banco Original S.A.' },
  { value: '712 - Banco Ourinvest S.A.' },
  { value: '623 - Banco PAN S.A.' },
  { value: '611 - Banco Paulista S.A.' },
  { value: '643 - Banco Pine S.A.' },
  { value: '658 - Banco Porto Real de Investimentos S.A.' },
  { value: 'Banco PSA Finance Brasil S.A.' },
  { value: '747 - Banco Rabobank International Brasil S.A.' },
  { value: 'Banco Randon S.A.' },
  { value: 'Banco RCI Brasil S.A.' },
  { value: '633 - Banco Rendimento S.A.' },
  { value: '741 - Banco Ribeirão Preto S.A.' },
  { value: '720 - BANCO RNX S.A' },
  { value: '120 - Banco Rodobens S.A.' },
  { value: '422 - Banco Safra S.A.' },
  { value: '33 - Banco Santander (Brasil) S.A.' },
  { value: '743 - Banco Semear S.A.' },
  { value: '276 - Banco Senff S.A.' },
  { value: '754 - Banco Sistema S.A.' },
  { value: '366 - Banco Société Générale Brasil S.A.' },
  { value: '637 - Banco Sofisa S.A.' },
  { value: '464 - Banco Sumitomo Mitsui Brasileiro S.A.' },
  { value: '82 - Banco Topázio S.A.' },
  { value: 'Banco Toyota do Brasil S.A.' },
  { value: '634 - Banco Triângulo S.A.' },
  { value: '18 - Banco Tricury S.A.' },
  { value: 'Banco Vipal S.A.' },
  { value: '653 - Banco Voiter S.A.' },
  { value: 'Banco Volkswagen S.A.' },
  { value: 'Banco Volvo Brasil S.A.' },
  { value: '655 - Banco Votorantim S.A.' },
  { value: '610 - Banco VR S.A.' },
  { value: '119 - Banco Western Union do Brasil S.A.' },
  { value: '124 - Banco Woori Bank do Brasil S.A.' },
  { value: '348 - Banco XP S.A.' },
  { value: 'Banco Yamaha Motor do Brasil S.A.' },
  { value: '81 - BancoSeguro S.A.' },
  { value: '21 - BANESTES S.A. Banco do Estado do Espírito Santo' },
  { value: '755 - Bank of America Merrill Lynch Banco Múltiplo S.A.' },
  { value: '320 - Bank Of China (Brasil) Banco Múltiplo S.A.' },
  { value: 'BB Banco de Investimento S.A.' },
  { value: '250 - BCV - Banco de Crédito e Varejo S.A.' },
  { value: '964 - BECKER FINANCEIRA S.A CREDITO, FINANCIAMENTO E INVESTIMENTO' },
  { value: 'BMW Financeira S.A. - Crédito, Financiamento e Investimento' },
  { value: '17 - BNY Mellon Banco S.A.' },
  { value: 'BOC Brasil S.A Crédito Financiamentos e Investimentos' },
  { value: '126 - BR Partners Banco de Investimento S.A.' },
  { value: '128 - Braza Bank S.A. Banco de Câmbio' },
  { value: '70 - BRB - Banco de Brasília S.A.' },
  { value: '92 - Brickell S.A. Crédito, Financiamento e Investimento' },
  { value: 'BRK S.A. Crédito, Financiamento e Investimento' },
  { value: 'BV Financeira S.A. - Crédito, Financiamento e Investimento' },
  { value: '104 - Caixa Econômica Federal' },
  { value: 'Caruana S.A. - Sociedade de Crédito, Financiamento e Investimento' },
  { value: '159 - Casa do Crédito S.A. - Sociedade de Crédito ao Microoempreendedor' },
  { value: 'Central das Cooperativas de Crédito do Estado de São Paulo - SICOOB CENTRAL CECRESP' },
  { value: '114-7 - Central das Cooperativas de Economia e Crédito Mútuo do Estado do Espírito Santo Ltda.' },
  { value: '477 - Citibank N.A.' },
  { value: '163 - Commerzbank Brasil S.A. - Banco Múltiplo' },
  { value: '85 - Cooperativa Central de Crédito - AILOS' },
  { value: '97 - Cooperativa Central de Crédito Noroeste Brasileiro Ltda.' },
  { value: '090-2 - Cooperativa Central de Economia e Crédito Mutuo - SICOOB UNIMAIS' },
  { value: '087-6 - Cooperativa Central de Economia e Crédito Mútuo das Unicreds de Santa Catarina e Paraná' },
  { value: '089-2 - Cooperativa de Crédito Rural da Região da Mogiana' },
  { value: '098-1 - CREDIALIANÇA COOPERATIVA DE CRÉDITO RURAL' },
  { value: 'Crediare S.A. - Credito, Financiamento e Investimento' },
  { value: 'Credicoamo Crédito Rural Cooperativa' },
  { value: 'Credjust - Cooperativa de Crédito Mútuo dos Integrantes da Justiça da 13ª Região LTDA.' },
  { value: 'Dacasa Financeira S.A.-Soc. de Créd. Financ. e Investimento' },
  { value: 'Daycoval Leasing - Banco Múltiplo S.A.' },
  { value: '487 - Deutsche Bank S.A. - Banco Alemão' },
  { value: '144 - Ebury Banco de Câmbio S.A.' },
  { value: 'Facta Financeira S.A. - Crédito Financiamento e Investimento' },
  { value: 'Finamax S.A. - Crédito, Financiamento e Investimento' },
  { value: '478 - Gazincred S.A. Sociedade de Credito, Financiamento e Investimento' },
  { value: '64 - Goldman Sachs do Brasil Banco Múltiplo S.A.' },
  { value: '78 - Haitong Banco de Investimento do Brasil S.A.' },
  { value: '62 - Hipercard Banco Múltiplo S.A.' },
  { value: 'HS Financeira S/A Crédito, Financiamento e Investimentos' },
  { value: '132 - ICBC do Brasil Banco Múltiplo S.A.' },
  { value: '492 - ING Bank N.V.' },
  { value: '139 - Intesa Sanpaolo Brasil S.A. - Banco Múltiplo' },
  { value: '652 - Itaú Unibanco Holding S.A.' },
  { value: '341 - Itaú Unibanco S.A.' },
  { value: 'J. Malucelli Distribuidora de Títulos e Valores Mobiliários LTDA.' },
  { value: '488 - JPMorgan Chase Bank, National Association' },
  { value: '399 - Kirton Bank S.A. - Banco Múltiplo' },
  { value: 'Lecca Crédito, Financiamento e Investimento S/A' },
  { value: '753 - Novo Banco Continental S.A. - Banco Múltiplo' },
  { value: '613 - Omni Banco S.A.' },
  { value: 'Omni SA Crédito Financiamento Investimento' },
  { value: '254 - Paraná Banco S.A.' },
  { value: 'Parati - Crédito Financiamento e Investimento S.A.' },
  { value: 'Pernambucanas Financiadora S.A. - Crédito, Financiamento e Investimento' },
  { value: '79 - Picpay Bank - Banco Múltiplo S.A.' },
  { value: 'Pine Investimentos Distribuidoras de Títulos e Valores Mobiliários LTDA' },
  { value: 'Portocred S.A. - Crédito, Financiamento e Investimento' },
  { value: 'PortoSeg S.A. - Crédito, Financiamento e Investimento' },
  { value: '329 - QI SOCIEDADE DE CREDITO DIRETO S.A.' },
  { value: 'Redecard S.A.' },
  { value: 'Santana S.A. - Crédito, Financiamento e Investimento' },
  { value: 'Santinvest S.A - Crédito, Financiamento e Investimentos' },
  { value: 'Sax S.A. - Crédito, Financiamento e Investimento' },
  { value: 'Scania Banco S.A.' },
  { value: '751 - Scotiabank Brasil S.A. Banco Múltiplo' },
  { value: '412 - Social Bank Banco Múltiplo' },
  { value: 'Sorocred Crédito, Financiamento e Investimento S.A.' },
  { value: '118 - Standard Chartered Bank (Brasil) S/A–Bco Invest.' },
  { value: '14 - State Street Brasil S.A. - Banco Comercial' },
  { value: '95 - Travelex Banco de Câmbio S.A.' },
  { value: '129 - UBS Brasil Banco de Investimento S.A.' },
  { value: '091-4 - Unicred Central do Rio Grande do Sul' },
  { value: '136 - Unicred do Brasil - Confederação Nacional das Cooperativas Centrais Unicred LTDA' },
  { value: 'UNIPRIME Central - Central Interestadual de Cooperativas de Crédito Ltda.' },
  { value: '84 - Uniprime Norte do Paraná - Coop de Economia e Crédito Mútuo dos Médicos, Profissionais das Ciências' },
  { value: 'Volvo Administradora de Consórcio LTDA.' },
  { value: '359 - Zema Credito, Financiamento e Investimento S.A.' },
  { value: '418 - Zipidin Soluções Digitais Sociedade de Crédito Direto S.A.' },
];

export const pronounsData = [
  { value: 'he' },
  { value: 'she' },
];

export const statesData = [
  { value: 'AC' },
  { value: 'AL' },
  { value: 'AP' },
  { value: 'AM' },
  { value: 'BA' },
  { value: 'CE' },
  { value: 'DF' },
  { value: 'ES' },
  { value: 'GO' },
  { value: 'MA' },
  { value: 'MT' },
  { value: 'MS' },
  { value: 'MG' },
  { value: 'PA' },
  { value: 'PB' },
  { value: 'PR' },
  { value: 'PE' },
  { value: 'PI' },
  { value: 'RJ' },
  { value: 'RN' },
  { value: 'RS' },
  { value: 'RO' },
  { value: 'RR' },
  { value: 'SC' },
  { value: 'SP' },
  { value: 'SE' },
  { value: 'TO' }
]

export const isMinor = (dateOfBirth: string) => {
  const dob = parseISO(dateOfBirth);
  const age = differenceInYears(new Date(), dob);

  return age < 18;
};

export const isMobile = () => {
  return (
    navigator.userAgent.indexOf('iPhone') !== -1 ||
    /Android/i.test(navigator.userAgent)
  );
};

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    console.log("Text copied successfully!");
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
}
