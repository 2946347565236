import React, {
  useEffect,
  useMemo,
  useState,
  memo,
  ReactElement,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import i18n from '../utils/i18n';
import useSwipeDirection from '../hooks/useSwipeDirection';

let interval: NodeJS.Timeout;

type Props = {
  suggestionList: Suggestion[];
};

const Suggestions: React.FC<Props> = ({ suggestionList }) => {
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(0);

  useSwipeDirection(
    () => {
      const index = (selectedIndex + 1) % suggestionList.length;
      setSelectedIndex(index);
    },
    () => {
      const index =
        selectedIndex === 0 ? suggestionList.length - 1 : selectedIndex - 1;
      setSelectedIndex(index);
    }
  );

  useEffect(() => {
    clearInterval(interval);

    interval = setInterval(() => {
      const index = (selectedIndex + 1) % suggestionList.length;
      setSelectedIndex(index);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [selectedIndex, suggestionList.length]);

  const selectSuggestion = (index: number) => {
    setSelectedIndex(index);
  };

  const renderDots = useCallback(() => {
    if (suggestionList.length <= 1) {
      return null;
    }

    let elements: ReactElement[] = [];
    for (let i = 0; i < suggestionList.length; i++) {
      const element: ReactElement = (
        <i
          key={suggestionList[i].username}
          onClick={() => selectSuggestion(i)}
          className={`font-xssss feather-circle ${
            selectedIndex === i
              ? 'text-warning bg-warning'
              : 'text-grey-600 bg-transparent'
          }`}
          style={{ cursor: 'pointer', marginRight: 5, borderRadius: 10 }}
        />
      );
      elements.push(element);
    }

    return elements;
  }, [selectedIndex, suggestionList]);

  const suggestion = useMemo(() => {
    return suggestionList.length ? suggestionList[selectedIndex] : null;
  }, [selectedIndex, suggestionList]);

  if (!suggestion) return null;

  return (
    <div className="card w-100 mb-5 shadow-xss rounded-xxl border-0 bg-white d-flex flex-column">
      <div className="card-body d-flex align-items-center">
        <h4 className="fw-700 mb-0 font-xssss text-grey-700">
          {i18n.t<string>('Suggestions.title')}
        </h4>
      </div>
      <div
        // style={{
        //   transition: 'transform 0.1s ease', // Add transition for smooth effect
        //   transform: `translateX(${position}px)`, // Move element horizontally
        // }}
      >
        <div className="d-flex flex-column">
          <img
            src={
              suggestion.profile.cover
                ? suggestion?.profile.cover
                : 'assets/images/banner-1200x250.png'
            }
            alt="avatar"
            style={{
              margin: 10,
              borderRadius: 10,
            }}
          />
          <div className="d-flex flex-row position-relative">
            <figure
              className="avatar position-absolute w100 z-index-1"
              style={{ top: '-20px', left: '-12px' }}
            >
              <img
                src={
                  suggestion.profile.avatar
                    ? suggestion.profile.avatar
                    : 'assets/images/user-100x100.png'
                }
                alt="avater"
                className="float-right p-1 bg-white rounded-circle"
                style={{
                  width: 60,
                  height: 60,
                }}
              />
            </figure>
            <h4
              className="fw-700 font-xsss mt-2 pl-10"
              style={{ overflow: 'hidden' }}
            >
              {suggestion.username}
            </h4>
          </div>
          <div className="d-flex flex-column p-4" style={{ height: 100 }}>
            <h4 className="fw-700 font-xssss text-grey-900">
              {i18n.t<string>('Suggestions.about')}
            </h4>
            <p
              className="fw-500 lh-26 font-xssss mb-0"
              style={{
                textOverflow: 'ellipsis',
                wordWrap: 'break-word',
                overflow: 'hidden',
                maxHeight: '100px',
              }}
            >
              {`${suggestion.profile.about ? suggestion.profile.about : ''}...`}
            </p>
          </div>
        </div>
        <div className="p-2">
          <button
            onClick={() => navigate(`/${suggestion.username}`)}
            className="form-control text-center text-white fw-600 bg-warning border-0 p-0 mt-5"
          >
            {i18n.t<string>('Suggestions.visit')}
          </button>
        </div>
      </div>
      {suggestionList.length > 1 && (
        <div className="d-flex justify-content-center align-items-center mb-2 mt-5">
          {renderDots()}
        </div>
      )}
    </div>
  );
};

export default memo(Suggestions);
