import { useCallback, useState } from 'react';
import { getFeed, getFeedByUsername, getPreview } from '../services/feed';
import { session } from '../signals/session';
import { AxiosError } from 'axios';
import { logout } from '../utils/session';
import { useNavigate } from 'react-router-dom';
import { getSuggestions } from '../services/user';
import { getPlansByCreatorId } from '../services/creator';
import { deletePost, likePost, unlikePost } from '../services/post';

const useFeed = (isMyProfile?: boolean) => {
  const navigate = useNavigate();

  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  const [feed, setFeed] = useState<NewFeed>({
    posts: [],
    pagination: {
      page: 0,
      totalPages: 0,
    },
    metadata: {
      subscribed: 'NEW',
      followed: false,
      totalVideos: 0,
      totalImages: 0,
      totalPosts: 0,
      profile: {
        id: '',
        userId: '',
        avatar: '',
        cover: '',
        about: '',
        createdAt: '',
        updatedAt: '',
      },
      creatorId: '',
    },
  });
  const [plan, setPlan] = useState<Plan>({
    id: '',
    creatorId: '',
    value: 0,
    interval: 0,
  });
  const [isFeedLoading, setIsFeedLoading] = useState(true);
  const [isFeedNextPageLoading, setIsFeedNextPageLoading] = useState(false);

  const handleApiError = useCallback(
    (error: AxiosError) => {
      switch (error.response?.status) {
        case 404: {
          if (!isMyProfile) {
            navigate('/error');
          }
          break;
        }
        case 401:
          logout();
          break;
      }
    },
    [isMyProfile, navigate]
  );

  const loadSuggestions = useCallback(async () => {
    try {
      const suggestionList = await getSuggestions();
      setSuggestions(suggestionList);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const loadPage = useCallback(
    async (username?: string) => {
      try {
        setIsFeedLoading(true);

        const feed: NewFeed | null = username
          ? await getFeedByUsername(username)
          : await getFeed();

        if (feed) {
          setFeed(feed);

          if (username && !isMyProfile) {
            const plans = await getPlansByCreatorId(feed.metadata.creatorId);

            if (plans.length) {
              setPlan(plans[0]);
            }
          }
        }
      } catch (error: any) {
        handleApiError(error);
      } finally {
        setIsFeedLoading(false);
      }
    },
    [handleApiError, isMyProfile]
  );

  const loadPreviewPage = useCallback(async () => {
    try {
      setIsFeedLoading(true);
      const previewFeed: NewFeed = await getPreview();

      setFeed(previewFeed);
    } catch (error: any) {
      handleApiError(error);
    } finally {
      setIsFeedLoading(false);
    }
  }, [handleApiError]);

  const onPost = useCallback(async () => {
    try {
      setIsFeedLoading(true);
      const feed = await getFeedByUsername(session.value.username);
      setFeed(feed);
    } catch (error) {
      console.log(error);
    } finally {
      setIsFeedLoading(false);
    }
  }, []);

  const onDeletePost = useCallback(async (postIndex: number) => {
    try {
      let newPosts = [
        ...feed.posts.slice(0, postIndex),
        ...feed.posts.slice(postIndex + 1),
      ];
      setFeed((preview) => {
        const newFeed: NewFeed = {
          ...preview,
          posts: newPosts,
        };
        return newFeed;
      });
      const post = feed.posts[postIndex];
      await deletePost(post.id);
    } catch (error) {
      console.log(error);
    }
  }, [feed.posts]);

  const fetchFeedNextPage = useCallback(
    async (username?: string) => {
      try {
        setIsFeedNextPageLoading(true);

        const nextFeedPage: NewFeed = username
          ? await getFeedByUsername(username, feed.pagination.page + 1)
          : await getFeed(feed.pagination.page + 1);

        setFeed((preview) => {
          const newFeed: NewFeed = {
            ...nextFeedPage,
            posts: [...preview.posts, ...nextFeedPage.posts],
          };
          return newFeed;
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsFeedNextPageLoading(false);
      }
    },
    [feed.pagination?.page]
  );

  const onLikePost = useCallback(
    async (postIndex: number) => {
      try {
        const post = feed.posts[postIndex];

        let likes = post.likes;
        let liked = post.metadata.liked;

        console.log(liked);

        if (liked) {
          unlikePost(post.id);
          liked = false;
          likes -= 1;
        } else {
          likePost(post.id);
          liked = true;
          likes += 1;
        }

        setFeed((preview) => {
          const posts = preview.posts;
          posts[postIndex].likes = likes;
          posts[postIndex].metadata.liked = liked;
          const newFeed: NewFeed = {
            ...preview,
            posts,
          };
          return newFeed;
        });
      } catch (error) {
        console.log(error);
      }
    },
    [feed.posts]
  );

  return {
    isFeedLoading,
    isFeedNextPageLoading,
    feed,
    plan,
    suggestions,
    loadSuggestions,
    loadPage,
    loadPreviewPage,
    onPost,
    onDeletePost,
    onLikePost,
    fetchFeedNextPage,
  };
};

export default useFeed;
