import React, { useState } from 'react';

import ProfileBannerModal from '../modals/ProfileBannerModal';
import { myProfile } from '../signals/profile';

const MyProfileBanner = () => {
  const [showProfileBannerModal, setShowProfileBannerModal] = useState(false);

  return (
    <div>
      <div className="card border-0 w-100">
          <img
            src={
              !myProfile.value.profile.cover
                ? 'assets/images/banner-1200x250.png'
                : myProfile.value.profile.cover
            }
            alt="avatar"
            style={{
              margin: 10,
              borderRadius: 10,
              maxHeight: 250,
              cursor: 'pointer',
            }}
            onClick={() => {
              setShowProfileBannerModal(true);
            }}
          />
      </div>
      <ProfileBannerModal
        isVisible={showProfileBannerModal}
        onClose={() => setShowProfileBannerModal(false)}
        onSave={(banner: any) => {
          setShowProfileBannerModal(false);
        }}
      />
    </div>
  );
};

export default MyProfileBanner;
