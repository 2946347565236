import React, { useState } from 'react';

import ProfilePhotoUploadModal from '../modals/ProfilePhotoModal';
import MyProfileBanner from './MyProfileBanner';
import { myProfile } from '../signals/profile';
import { session } from '../signals/session';
import { Link } from 'react-router-dom';
import { isCreator } from '../signals/creator';
import { copyToClipboard } from '../utils/data';
import { Toast } from 'react-bootstrap';

const MyProfileCard = () => {
  const [showProfileImageUpload, setShowProfileImageUpload] = useState(false);

  const [show, setShow] = useState(false);

  return (
    <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
      <MyProfileBanner />
      <div className="card-body p-0 position-relative">
        <figure
          className="avatar position-absolute w100 z-index-1"
          style={{ top: '-40px', left: '30px', cursor: 'pointer' }}
          onClick={() => {
            setShowProfileImageUpload(true);
          }}
        >
          <img
            src={
              myProfile.value.profile.avatar ?? 'assets/images/user-100x100.png'
            }
            alt="avater"
            className="float-right p-1 bg-white rounded-circle w-100"
          />
        </figure>
        <div className="d-flex flex-row w-100 justify-content-between">
          <div className="d-flex flex-column">
            <h4 className="fw-700 font-sm mt-2 pl-15">
              {session.value.username}
            </h4>
            <div className="d-flex flex-row">
              <h4 className="fw-500 font-xssse mb-5 me-2 pl-15">{`@${session.value.username}`}</h4>
              {isCreator.value && (
                <i
                  className="font-md feather-copy me-2 text-warning"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={async () => {
                    const origin = window.location.origin;
                    const url = `${origin}/${session.value.username}`;

                    await copyToClipboard(url);

                    setShow(true);
                  }}
                />
              )}
            </div>
          </div>
          {isCreator.value && (
            <Link
              to="/settings"
              className="nav-content-btn open-font h-auto pt-2 pb-2"
            >
              <i
                className="font-xl feather-settings me-3 text-warning mt-2"
                style={{
                  cursor: 'pointer',
                }}
              />
            </Link>
          )}
        </div>
      </div>

      {/* <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs flex-row d-flex p-2 align-items-center">
        <img
          src="assets/images/instagram-logo.png"
          style={{
            maxHeight: 35,
            maxWidth: 35,
            cursor: 'pointer',
            marginRight: 10,
          }}
          alt=""
          onClick={() => window.open('https://www.instagram.com/', '_blank')}
        />
        <img
          src="assets/images/x-logo.png"
          style={{
            maxHeight: 25,
            maxWidth: 25,
            cursor: 'pointer',
            marginRight: 10,
          }}
          alt=""
          onClick={() => window.open('https://x.com/', '_blank')}
        />
        <img
          src="assets/images/tiktok.png"
          style={{
            maxHeight: 25,
            maxWidth: 25,
            cursor: 'pointer',
            marginRight: 8,
          }}
          alt=""
          onClick={() => window.open('https://tiktok.com/', '_blank')}
        />
        <img
          src="assets/images/site-logo.png"
          style={{
            maxHeight: 35,
            maxWidth: 35,
            cursor: 'pointer',
          }}
          alt=""
          onClick={() => false}
        />
      </div> */}
      <ProfilePhotoUploadModal
        isVisible={showProfileImageUpload}
        onClose={() => {
          setShowProfileImageUpload(false);
        }}
      />
      <Toast
        show={show}
        style={{
          width: 135,
          position: 'absolute',
          zIndex: 2,
          bottom: 0,
          right: 0,
        }}
        onClose={() => setShow(false)}
        delay={2000}
        autohide
      >
        <Toast.Body>✅ Link copiado</Toast.Body>
      </Toast>
    </div>
  );
};

export default MyProfileCard;
