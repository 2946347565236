import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { init, initialized } from '../../utils/i18n';

const PublicRoute = ({ children }: any) => {
  const [loading, setLoading] = useState(false);

  if (loading) {
    return (
      <div className="d-flex flex-grow-1 flex-column justify-content-center align-items-center mt-5">
        <Spinner animation="border" role="status" className="text-warning" />
      </div>
    );
  }

  const initializeI18n = async () => {
    setLoading(true);
    await init();
    setLoading(false);
  }

  if (!initialized) {
    initializeI18n();
  }

  return children;
}

export default PublicRoute;
